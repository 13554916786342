import PropTypes from "prop-types";
import React from "react";

import { labUrl } from "../../conf/conf";
import Form from "../form/Form";
import addI18nContextConsumer from "../i18n/addI18nContextConsumer";
import SelectLanguage from "../select-language/SelectLanguage";
import i18n from "./Subscribe.i18n.json";
import styles from "./Subscribe.module.scss";

function Subscribe({ locale, strings }) {
  const footer = (
    <span>
      {strings["already"]} <a href={labUrl}>{strings["connect"]}</a>
    </span>
  );
  return (
    <div className={styles["container"]}>
      <div className={styles["langue"]}>
        <SelectLanguage />
      </div>
      <div className={styles["title"]}>{strings["title"]}</div>
      <div className={styles["form"]}>
        <Form
          btnLabel={strings["validate"]}
          footer={footer}
          name="Subscribe_Jawg"
          title={strings["toConnect"]}
        >
          <div className={styles["pair"]}>
            <Form.Input title={strings["firstname"]} />
            <Form.Input title={strings["lastname"]} />
          </div>
          <Form.Input title={strings["email"]} />
          <Form.Input title={strings["password"]} />
          <Form.Input title={strings["confirm_password"]} />
        </Form>
      </div>
      <div className={styles["list"]}>
        <div>{strings["free"]}</div>
        <div>{strings["custom"]}</div>
        <div>{strings["site"]}</div>
      </div>
    </div>
  );
}

Subscribe.propTypes = {
  locale: PropTypes.string.isRequired,
  /*eslint-disable react/forbid-prop-types*/
  strings: PropTypes.object.isRequired,
  /*eslint-enable react/forbid-prop-types*/
};

export default addI18nContextConsumer(i18n)(Subscribe);
